
.App {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    background: var(--unnamed-color-f3edff) url(./Pattern.png) 0 0;
    background-size: 100px;
    background-attachment: fixed;
}

html,body, #root {
    height:100%
}

.Header {
    background: var(--inai-purple);
    display: flex;
    position: relative;
    flex: 0 1
}

nav a{
    display: inline-block;
    text-align: center;
    color: #fff;
}

nav a.active{
    background: white;
    color: #03a9f4;
}

.Content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

:root {

    /* Colors: */
    --unnamed-color-ffda9a: #FFDA9A;
    --unnamed-color-f3edff: #F3EDFF;
    --inai-purple: #A198B3;
    --ina-orange: #F8BF04;
    --ika-purple: #564F68;
    --ink-black: #000000;
    }